import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppConfiguration } from '../app.configuration';
import { NotAuthorizedComponent } from '../components/dialogs/not-authorized/not-authorized.component';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class MainGuard implements CanActivate {

  constructor(private dataService: DataService,
    private authService: AuthService,
    public dialog: MatDialog) { }

  canActivate(next: ActivatedRouteSnapshot): boolean {

    this.dataService.accessToken = next.queryParams['jwt'];
    this.dataService.sessionCacheKey = next.queryParams['sessionCacheKey'];
    this.dataService.corporationKey = next.queryParams['c'];
    this.dataService.role = next.queryParams['rr'];
    this.dataService.credentials = next.queryParams['aa'];

    if (this.dataService.accessToken &&
      this.dataService.sessionCacheKey &&
      this.dataService.role &&
      this.dataService.credentials &&
      this.dataService.corporationKey) {

      this.authService.getValidateTokenRol(this.dataService.credentials, this.dataService.role, this.dataService.corporationKey).subscribe(res => {
        this.dataService.userName = res["userName"];
      });

      return true;
    }

    const dialogRef = this.dialog.open(NotAuthorizedComponent, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      window.location.href = `${AppConfiguration.LOGOUT_URL}${this.dataService.sessionCacheKey}`;
    });

    return false;
  }
}
