import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DataAudit } from '../shared/models/audit';
import { AppConfiguration } from '../app.configuration';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  baseUrl = "";
  filters;
  filterCsv;

  private data: any = undefined;
  public accessToken!: string;
  public userName!: string;
  public sessionCacheKey!: string;
  public corporationKey!: string;
  public showSpinner!: boolean;
  public role!: string;
  public credentials!: string;


  constructor(private httpClient: HttpClient) {
  }

  list(): any {
    return this.httpClient.get<DataAudit>(this.baseUrl + '/api/auditdata');
  }

  validateToken(dataToken): any {
    return this.httpClient.post<any>("https://auditdummy/api/validatetoken", dataToken);
  }

  getFileCsv(filters) {

    let allFilter = "";
    let filterClinics = "";
    let filterPatient = "";
    let filterRols = "";
    let filterSearchEvent = "";
    let filterTypeEvent = "";
    let filterUser = "";
    let filterStartDate = "";
    let filterEndDate = "";
    let filterEndDateStorage = "";

    filterStartDate = filters.startTime._i.substring(3, 5)
    filterStartDate = filters.startTime._i.substring(0, 2) + "-" + filterStartDate;;
    filterStartDate = filters.startTime._i.substring(6, 11) + "-" + filterStartDate;

    filterEndDateStorage = localStorage.getItem("dateEnd");
    filterEndDate = filterEndDateStorage.substring(3, 5)
    filterEndDate = filterEndDateStorage.substring(0, 2) + "-" + filterEndDate;;
    filterEndDate = filterEndDateStorage.substring(6, 11) + "-" + filterEndDate;

    allFilter = "?" + "StartTime=" + filterStartDate + "&" + "EndTime=" + filterEndDate

    if (filters.clinic) {
      filters.clinic.forEach(function (clinic, index) {
        console.log(`${index} : ${clinic}`);
        filterClinics = "&" + "Clinics=" + clinic + filterClinics;
      });
      allFilter = allFilter + filterClinics
    } else if (filters.multipleClinics == true) {
      filterClinics = "&" + "Clinics=" + "-1";
      allFilter = allFilter + filterClinics
    } else {
      filterClinics = "&" + "Clinics=" + filters.singleClinicId;
      allFilter = allFilter + filterClinics
    }


    if (filters.patientId != 0) {
      filterPatient = "&" + "Patient=" + filters.patientId;
      allFilter = allFilter + filterPatient.replace(/ /g, "");
    }

    if (filters.role != "") {
      filterRols = "&" + "RoleId=" + filters.role;
      allFilter = allFilter + filterRols.replace(/ /g, "");
    }

    if (filters.searchEvent != "") {
      filterSearchEvent = "&" + "LogEvent=" + filters.searchEvent;
      allFilter = allFilter + filterSearchEvent.replace(/ /g, "");
    }

    if (filters.typeEvent != "") {
      filterTypeEvent = "&" + "Type=" + filters.typeEvent;
      allFilter = allFilter + filterTypeEvent.replace(/ /g, "");
    }

    if (filters.userId != 0) {
      filterUser = "&" + "UserId=" + filters.user;
      allFilter = allFilter + filterUser.replace(/ /g, "");
    }

    allFilter = allFilter + "&FileType=.csv";
    console.log("allFiltersCsv", allFilter);
    let url = environment.baseUrlDev;

    return this.httpClient.get<any>(url + "api/logs/file" + allFilter, {
      'responseType': 'arraybuffer' as 'json',
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.accessToken}`)
    });

  }

  setData(data: any) {
    this.data = data;
  }

  getData(): any {
    return this.data;
  }

  clean() {
    this.accessToken = undefined;
    this.userName = undefined;
  }

}