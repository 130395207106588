import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './components/app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material/material.module';
import { AtlasUIModule } from './atlas-ui/atlas-ui.module';
import { AtlasErrorsHandlerService } from '@wellsky/atlas-ui/core';
import { IMaskModule } from 'angular-imask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppConfiguration } from './app.configuration';
import { NotAuthorizedComponent } from './components/dialogs/not-authorized/not-authorized.component';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    NotAuthorizedComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    HttpClientModule,
    MaterialModule,
    AppRoutingModule,
    AtlasUIModule,
    IMaskModule,
  ],
  providers: [
    HttpClientModule,
    DatePipe,
    AppConfiguration,
    {
      provide: AtlasErrorsHandlerService,
      useClass: AtlasErrorsHandlerService,
     
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }