import { NgModule } from '@angular/core';
// For build version
import { AtlasAuthModule } from '@wellsky/atlas-ui/auth';
import { AtlasButtonModule } from '@wellsky/atlas-ui/button';
import { AtlasBreadcrumbsModule } from '@wellsky/atlas-ui/breadcrumbs';
import { AtlasCheckboxModule } from '@wellsky/atlas-ui/checkbox';
import { AtlasChipsModule } from '@wellsky/atlas-ui/chips';
import { AtlasInputModule } from '@wellsky/atlas-ui/input';
import { AtlasRadioModule } from '@wellsky/atlas-ui/radio';
import { AtlasSearchModule } from '@wellsky/atlas-ui/search';
import { AtlasSelectModule } from '@wellsky/atlas-ui/select';
import { AtlasSidenavModule } from '@wellsky/atlas-ui/sidenav';
import { AtlasTabsModule } from '@wellsky/atlas-ui/tabs';
import { AtlasTextareaModule } from '@wellsky/atlas-ui/textarea';
import { AtlasTimepickerModule } from '@wellsky/atlas-ui/timepicker';
import { AtlasTooltipModule } from '@wellsky/atlas-ui/tooltip';
import { AtlasMenuModule } from '@wellsky/atlas-ui/menu';
import { AtlasDatepickerModule } from '@wellsky/atlas-ui/datepicker';
import { AtlasBannersModule } from '@wellsky/atlas-ui/banners';
import { AtlasDialogModule } from '@wellsky/atlas-ui/dialog';
import { AtlasCardModule } from '@wellsky/atlas-ui/card';
import { AtlasTopNavModule } from '@wellsky/atlas-ui/topnav';
import { AtlasSpinnerModule } from '@wellsky/atlas-ui/spinner';
import { AtlasCryptoModule } from '@wellsky/atlas-ui/crypto';
import { AtlasFooterModule } from '@wellsky/atlas-ui/ctafooter'; 

// For local development
// import { AtlasAuthModule } from 'atlas-ui/auth';
// import { AtlasButtonModule } from 'atlas-ui/button';
// import { AtlasBreadcrumbsModule } from 'atlas-ui/breadcrumbs';
// import { AtlasCheckboxModule } from 'atlas-ui/checkbox';
// import { AtlasChipsModule } from 'atlas-ui/chips';
// import { AtlasInputModule } from 'atlas-ui/input';
// import { AtlasRadioModule } from 'atlas-ui/radio';
// import { AtlasSearchModule } from 'atlas-ui/search';
// import { AtlasSelectModule } from 'atlas-ui/select';
// import { AtlasSidenavModule } from 'atlas-ui/sidenav'; 
// import { AtlasTabsModule } from 'atlas-ui/tabs';
// import { AtlasTextareaModule } from 'atlas-ui/textarea';
// import { AtlasTimepickerModule } from 'atlas-ui/timepicker';
// import { AtlasTooltipModule } from 'atlas-ui/tooltip';
// import { AtlasDatepickerModule } from 'atlas-ui/datepicker';
// import { AtlasMenuModule } from 'atlas-ui/menu';
// import { AtlasBannersModule } from 'atlas-ui/banners';
// import { AtlasDialogModule } from 'atlas-ui/dialog';
// import { AtlasCardModule } from 'atlas-ui/card';
// import { AtlasTopNavModule } from 'atlas-ui/topnav';
// import { AtlasSpinnerModule } from 'atlas-ui/spinner';
// import { AtlasCryptoModule } from 'atlas-ui/crypto';
// import { AtlasFooterModule } from 'atlas-ui/ctafooter';

@NgModule({
  imports: [
    AtlasAuthModule,
    AtlasButtonModule,
    AtlasBreadcrumbsModule,
    AtlasCheckboxModule,
    AtlasChipsModule,
    AtlasInputModule,
    AtlasRadioModule,
    AtlasSearchModule,
    AtlasSelectModule,
    AtlasTabsModule,
    AtlasTextareaModule,
    AtlasTimepickerModule,
    AtlasTooltipModule,
    AtlasMenuModule,
    AtlasDatepickerModule,
    AtlasBannersModule,
    AtlasDialogModule,
    AtlasCardModule,
    AtlasSidenavModule,
    AtlasTopNavModule,
    AtlasSpinnerModule,
    AtlasCryptoModule,
    AtlasFooterModule,
  ],
  declarations: [],
  exports: [
    AtlasAuthModule,
    AtlasButtonModule,
    AtlasBreadcrumbsModule,
    AtlasCheckboxModule,
    AtlasChipsModule,
    AtlasInputModule,
    AtlasRadioModule,
    AtlasSearchModule,
    AtlasSelectModule,
    AtlasTabsModule,
    AtlasTextareaModule,
    AtlasTimepickerModule,
    AtlasTooltipModule,
    AtlasMenuModule,
    AtlasDatepickerModule,
    AtlasBannersModule,
    AtlasDialogModule,
    AtlasCardModule,
    AtlasSidenavModule,
    AtlasTopNavModule,
    AtlasSpinnerModule,
    AtlasCryptoModule,
    AtlasFooterModule,
  ]
})
export class AtlasUIModule { }
