import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfiguration } from '../app.configuration';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
    private appConfiguration: AppConfiguration) { }

  getValidateTokenRol(credentials: string, role: string, corporationKey: string) {
    return this.http.get(`${environment.baseUrlDev}api/Auth/auth-user?EncodedUserCredentials=${credentials}&EncodedUserRole=${role}&CorporationKey=${corporationKey}`, {
      headers: this.appConfiguration.getHeader()
    }).pipe(catchError(this.errorHandler));
  }

  errorHandler() {
    return throwError(() => { const error: any = new Error(`Error in validation token`) });
  }
}
