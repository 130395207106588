import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "./services/data.service";

@Injectable()
export class AppConfiguration {

    static LOGOUT_URL = 'https://qa.kinnser.net/am/logout.cfm?sessionCacheKey=';
    static AUTH_ATLAS = 'https://auth.stable.wellsky.io/connect/token';
    static LOCAL_BACKEND = 'https://sigma.wshaudit-dev-dmz.prj.gcp.wellsky.io/';

    constructor(private dataService: DataService) { }

    getHeader(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Authorization', `Bearer ${this.dataService.accessToken}`);
        return headers;
    }
}